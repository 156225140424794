import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import Logo from '../../logo.png';
import CreditCardImg from '../../img/CreditCardImg.png';
import FpsImg from '../../img/FpsImg.png';
import PaymeImg from '../../img/PaymeImg.png';
import TokenImg from '../../img/TokenImg.png';
import TokenIcon from '../../img/icon-DancingTicket.png';

import Header from '../../components/Header.js';

export default function ProfilePayment() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const token = TokenService.getToken();
  const { room_id } = useParams();
  const item = JSON.parse(LocalStorageTTL.getWithExpiry('selectedPackage'));

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  if(item){
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
                <div className="app-page-topic">
                  <p>付款方法</p>
                  <p>Payment Method</p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Grid container rowSpacing={2} columnSpacing={2}>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <button
                      type="submit"
                      className="app-payment-container-button app-payment-container-visa"
                      onClick={event =>  window.location.href='payment/card'}
                    >
                      <img className="" src={CreditCardImg}/>
                    </button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <button
                      type="submit"
                      className="app-payment-container-button app-payment-container-fps"
                      onClick={event =>  window.location.href='payment/fps'}
                    >
                      <img className="" src={FpsImg}/>
                    </button>
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <button
                      type="submit"
                      className="app-payment-container-button app-payment-container-payme"
                      onClick={event =>  window.location.href='payment/payme'}
                    >
                      <img className="" src={PaymeImg}/>
                    </button>
                  </Grid>
                </Grid>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3',
                    marginTop:'16px'
                  }}
                >
                  <CardContent>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div className="app-payment-details-header">
                        <img className="img-tokenIcon" src={TokenIcon}/> x {item.token_num}
                      </div>
                      <hr/>
                      <div className="app-payment-details-content">
                        {item.currency}${item.price} {item.isHot==1?"[熱門]":""}
                      </div>
                      <div className="app-payment-details-content">
                        限期 Expiration period
                      </div>
                      <div className="app-payment-details-content">
                        {item.expiry_day_period}日/Days
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
                <div className="app-bottom-container-booking">
                  <div className="app-bottom-container-booking-text">*一但確認，將不可進行更改。</div>
                  <div className="app-bottom-container-booking-text">*Once confirm, it cannot be undo.</div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
