import React, { useState , useRef} from 'react';
import Lottie from 'react-lottie';
import Animate from 'rc-animate';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Login.css';
import Logo from '../../logo.png';

import i18n from '../../i18n';
import { useTranslation } from "react-i18next";

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import InputAdornment from '@mui/material/InputAdornment';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import InstagramIcon from '@mui/icons-material/Instagram';

import Header from '../../components/Header.js';

import { FileUploader } from "react-drag-drop-files";
import AWS from 'aws-sdk'
window.Buffer = window.Buffer || require("buffer").Buffer;

function setToken(userToken) {
  LocalStorageTTL.setWithExpiry('token', userToken);
}
function getToken() {
  const userToken = LocalStorageTTL.getWithExpiry('token');
  return userToken
}
export default function Register (props) {
  const { t } = useTranslation();

  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [fullname, setFullname] = useState(LocalStorageTTL.getWithExpiry('visitorName'));
  const [contact, setContact] = useState(LocalStorageTTL.getWithExpiry('visitorPhone'));
  const [userEmail, setUserEmail] = useState();
  const [igName, setIgName] = useState('');

  

  const isPasswordValid = (password) => {
    const passwordRegex = /^.{6,12}$/;
    return passwordRegex.test(password);
  };


  const isTraditionalChinese = i18n.language == 'zh_hk';
  //--
  const buttonRef = useRef();
  //--popup modal
  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 380,
    textAlign: 'center',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '24px',
    padding: '50px',
    boxShadow: 24,
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //--fileupload
  const [progress , setProgress] = useState(0);
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const fileTypes = ["JPG", "JPEG", "PNG", "GIF", "HEIC", "HEIF"];
  const fileChange = (file) => {
    buttonRef.current.disabled = true;
    setFile(file);
    //--Start s3 upload
    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_ACCESS_KEY
    });
    const myBucket = new AWS.S3({
        params: { Bucket: S3_BUCKET},
        region: REGION,
        dirName: DIR_NAME
    });
    const current_time = Math.floor(new Date().getTime() / 1000);
    const random_index = (Math.floor(Math.random() * 900) + 100);
    const params = {
        ACL: 'public-read',
        Body: file,
        Bucket: S3_BUCKET,
        Key: DIR_NAME+"User_"+current_time+"_"+random_index+"_"+file.name
    };
    myBucket.putObject(params)
        .on('httpUploadProgress', (evt) => {
            setProgress(Math.round((evt.loaded / evt.total) * 100))
        })
        .send((err) => {
            if (err){
              alert("Upload image fail! Please try another payment method.");
            }else{
              setImageUrl("https://s3.ap-northeast-2.amazonaws.com/com.01tech.share/"+params.Key);
            }
            buttonRef.current.disabled = false;
        });
  };
  const S3_BUCKET ='com.01tech.share';
  const REGION ='ap-northeast-2';
  const DIR_NAME = 'projects/212studio/user_image/';
  const ACCESS_KEY ='AKIAYEH7O4N7S6HSYZFB';
  const SECRET_ACCESS_KEY ='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';
  const registerSubmit = async e => {
    e.preventDefault();
    //--prevent multiple submit
    buttonRef.current.disabled = true;

      // Check if password is valid
  if (!isPasswordValid(password)) {
    // Show an alert or a popup
    alert(t('Please make sure the password fulfills the requirement'));
    buttonRef.current.disabled = false;
    return;
  }

    if(imageUrl){
      registerUser(
        userName,
        fullname,
        password,
        contact,
        userEmail,
        imageUrl,
        igName
      );
    }else{
      buttonRef.current.disabled = false;
      handleOpen();
    }
  };
  async function registerUser(userName, fullname, password, contact, userEmail, image_url, ig_name) {
    const proceed_link = LocalStorageTTL.getWithExpiry('proceed_link');
    try
    {
      axios.post('', {
        "name": "register",
        "param": {
          "username": userName,
          "fullname": fullname,
          "password": password,
          "contact": contact,
          "email": userEmail,
          "image_url": image_url,
          "ig_name": ig_name
        }
      }, {
        timeout: 30000 // Increase the timeout value
      })
      .then((response) => {
        console.log(response);
        if(response.status == 200){
          if(response.data.status == 200){
            //--SUCCESS
            localStorage.removeItem('visitorName');
            localStorage.removeItem('visitorPhone');
            alert(response.data.message);
            if(proceed_link){
              window.location.replace(proceed_link);
            }else{
              window.location.href="/home";
            }
          }else{
            alert(response.data.message);
            buttonRef.current.disabled = false;
          }
        }else{
          alert((i18n.language == 'zh_hk')?"連接出現問題！請聯絡相關技術人員！":"连接出现问题！请联络相关技术人员！");
          buttonRef.current.disabled = false;
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    catch (error)
    {
      alert(error);
    }
  };
  return(
    <div className="app-wrapper">
      <div className="app-page ">
        <Container>
          <form onSubmit={registerSubmit}>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="login-container-text">
                  <p><label className="card-container-username">新會員 </label>註冊</p>
                  <p><label className="card-container-username">Member </label>Register</p>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className="login-page-form">
                  <div>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="登入名稱 Login Name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DriveFileRenameOutlineOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          color="warning"
                          focused
                          fullwidth
                          required
                          value={userName}
                          onChange={e => setUserName(e.target.value.replace(/\s/g, ''))}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .MuiInputBase-input:after":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="姓名 Name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BadgeOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          color="warning"
                          focused
                          fullwidth
                          value={fullname}
                          onChange={e => setFullname(e.target.value.replace(/\s/g, ''))}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          inputProps={{ maxLength: 8 }}
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="電話號碼 Contact"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalPhoneOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          color="warning"
                          type="tel"
                          focused
                          fullwidth
                          value={contact}
                          // onChange={e => setContact(e.target.value.replace(/\D/g, ''))}
                          onChange={e => setContact(e.target.value)}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="電子郵件 Email"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          type="email"
                          color="warning"
                          focused
                          fullwidth
                          value={userEmail}
                          onChange={e => setUserEmail(e.target.value.trim())}
                          onKeyDown={e => ( e.key === " " )? e.preventDefault():""}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          inputProps={{ maxLength: 20 }}
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="Instagram (optional)"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <InstagramIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          color="warning"
                          focused
                          fullwidth
                          value={igName}
                          onChange={e => setIgName(e.target.value)}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          inputProps={{ maxLength: 8 }}
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="登入密碼 Password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <HttpsOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          type="password"
                          color="warning"
                          focused
                          fullwidth
                          required
                          value={password}
                          // onChange={e => setPassword(e.target.value.replace(/\s/g, ''))}
                          onChange={e => setPassword(e.target.value)}
                        />
                      </div>
                      <span className='app-register-text'>*Password requires: 6-12 characters; uppercase, lowercase, numbers, blocks, and symbols are allowed</span>
                    </label>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <FileUploader
                  classes="image_drop_zone"
                  label="請上載你的個人照片 Please upload your photo"
                  handleChange={fileChange}
                  name="file"
                  types={fileTypes}
                  hoverTitle="確認上傳"
                />
                <img className="app-logo-img" src={imageUrl}/>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  slots={{ backdrop: Backdrop }}
                  slotProps={{
                    backdrop: {
                      timeout: 500,
                    },
                  }}
                >
                  <Fade in={open}>
                    <Box sx={modal_style}>
                      <Typography id="transition-modal-title" variant="h6" component="h2">
                        注意
                      </Typography>
                      <Typography id="transition-modal-title" variant="h6" component="h2">
                        Attention
                      </Typography>
                      <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        請上傳個人照片
                      </Typography>
                      <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                        Please upload your personal photo.
                      </Typography>
                      <br/>
                      <button type="button" className="modal-bottom-button black-button" onClick={handleClose}>OK</button>
                    </Box>
                  </Fade>
                </Modal>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="login-page-bottom">
                  <div className="login-bottom">
                    <div className="login-bottom-container">
                      <button ref={buttonRef} type="submit" className="login-bottom-container-submit" onClick={registerSubmit}>註冊 Register</button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </div>
  )
};