import React, { useState } from 'react';
import Lottie from 'react-lottie';
import Animate from 'rc-animate';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './Login.css';
import Logo from '../../logo.png';

import i18n from '../../i18n';
import { useTranslation } from "react-i18next";

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

import Header from '../../components/Header.js';

function setToken(userToken) {
  LocalStorageTTL.setWithExpiry('token', userToken);
}
function getToken() {
  const userToken = LocalStorageTTL.getWithExpiry('token');
  return userToken
}
function setVisitorName(value) {
  LocalStorageTTL.setWithExpiry('visitorName', value);
}
function setVisitorPhone(value) {
  LocalStorageTTL.setWithExpiry('visitorPhone', value);
}
function setVisitorToken(name, phone) {
  LocalStorageTTL.setWithExpiry('token', "visitor_token");
  LocalStorageTTL.setWithExpiry('visitorName', name);
  LocalStorageTTL.setWithExpiry('visitorPhone', phone);
}
async function loginUser(userName, password) {
    const proceed_link = LocalStorageTTL.getWithExpiry('proceed_link');
    try
    {
       axios.post('', {
        "name":"generateToken",
        "param": {
            "username":userName,
            "password":password
        }
      })
      .then((response) => {
        console.log(response);
        if(response.status == 200){
          console.log(response.data);
          if(response.data.status == 200){
            setToken(response.data.token);
            LocalStorageTTL.setWithExpiry('dontShowRentalTable', 0);
            localStorage.removeItem('visitorName');
            localStorage.removeItem('visitorPhone');
            localStorage.removeItem('member_only');
            if(proceed_link){
              localStorage.removeItem('proceed_link');
              window.location.replace(proceed_link);
            }else{
              window.location.replace(document.referrer);
            }
          } else {
            alert((i18n.language == 'zh_hk')?"用戶名稱或密碼輸入錯誤":"用戶名稱或密码输入错误");
          }
        }else{
          alert((i18n.language == 'zh_hk')?"用戶名稱或密碼輸入錯誤":"用戶名稱或密码输入错误");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }
    catch (error)
    {
      alert(error);
    }
};

export default function Login (props) {
  const { t } = useTranslation();
  const member_only = LocalStorageTTL.getWithExpiry('member_only');
  const proceed_link = LocalStorageTTL.getWithExpiry('proceed_link');
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();

  const [visitorName, setVisitorName] = useState();
  const [visitorPhone, setVisitorPhone] = useState();

  const isTraditionalChinese = i18n.language == 'zh_hk';

  const loginSubmit = async e => {
    e.preventDefault();
    await loginUser(userName, password);
  };
  const visitorSubmit = async e => {
    e.preventDefault();
    setVisitorToken(visitorName, visitorPhone);
    if(proceed_link){
      localStorage.removeItem('proceed_link');
      window.location.replace(proceed_link);
    }else{
      window.location.replace(document.referrer);
    }
  };
  if(member_only){
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="login-container-text">
                  <label>以<label className="card-container-username">會員</label>身份查看</label>
                </div>
                <div className="login-container-text">
                  <label>View as<label className="card-container-username">Member</label></label>
                </div>
                <div className="login-page-form">
                  <form onSubmit={loginSubmit}>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          id=""
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .MuiInputBase-input:after":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="登入名稱 Login Name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BadgeOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          color="warning"
                          focused
                          fullwidth
                          value={userName}
                          onChange={e => setUserName(e.target.value.replace(/\s/g, ''))}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          id="standard-basic"
                          inputProps={{ maxLength: 8 }}
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="登入密碼 Password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <HttpsOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          type="password"
                          color="warning"
                          focused
                          fullwidth
                          value={password}
                          onChange={e => setPassword(e.target.value.replace(/\s/g, ''))}
                        />
                      </div>
                    </label>
                    <div className="login-bottom">
                      <div className="login-bottom-container">
                        <button type="submit" className="login-bottom-container-submit">登入 Login</button>
                        <div class="login-bottom-container-text">
                          <div class="login-bottom-container-text-left">
                            <label onClick={event =>  window.location.href='/home/register'}>用戶註冊 Register</label>
                          </div>
                          <div class="login-bottom-container-text-right">
                            <label onClick={event =>  window.location.href='/home/forgot'}>忘記密碼 Forgot Password</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={5}>
                <div className="login-container-text">
                  <label>以<label className="card-container-username">會員</label>身份查看</label>
                </div>
                <div className="login-container-text">
                  <label>View as<label className="card-container-username">Member</label></label>
                </div>
                <div className="login-page-form">
                  <form onSubmit={loginSubmit}>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          id=""
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .MuiInputBase-input:after":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="姓名 Name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BadgeOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          color="warning"
                          focused
                          fullwidth
                          value={userName}
                          onChange={e => setUserName(e.target.value.replace(/\s/g, ''))}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          id="standard-basic"
                          inputProps={{ maxLength: 8 }}
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="登入密碼 Password"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <HttpsOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          type="password"
                          color="warning"
                          focused
                          fullwidth
                          value={password}
                          onChange={e => setPassword(e.target.value.replace(/\s/g, ''))}
                        />
                      </div>
                    </label>
                    <div className="login-bottom">
                      <div className="login-bottom-container">
                        <button type="submit" className="login-bottom-container-submit">登入 Login</button>
                        <div class="login-bottom-container-text">
                          <div class="login-bottom-container-text-left">
                            <label onClick={event =>  window.location.href='/home/register'}>用戶註冊 Register</label>
                          </div>
                          <div class="login-bottom-container-text-right">
                            <label onClick={event =>  window.location.href='/home/forgot'}>忘記密碼 Forgot Password</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
                <div className="login-page-middle-break">
                  <div className=""></div>
                  <div className="vertical-line"></div>
                  <div className="horizontal-line"></div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={5}>
                <div className="login-container-text">
                  <label>以<label className="card-container-username">訪客</label>身份查看</label>
                </div>
                <div className="login-container-text">
                  <label>View as<label className="card-container-username">Visitor</label></label>
                </div>
                <div className="login-page-form visitor-form">
                  <form onSubmit={visitorSubmit}>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          id=""
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .MuiInputBase-input:after":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="姓名 Name"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BadgeOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          variant="standard"
                          color="warning"
                          focused
                          fullwidth
                          value={visitorName}
                          onChange={e => setVisitorName(e.target.value.replace(/\s/g, ''))}
                        />
                      </div>
                    </label>
                    <label className="login-page-form-label">
                      <div className="login-page-form-block">
                        <TextField
                          id=""
                          // inputProps={{ maxLength: 8 }}
                          sx={{
                            "& .MuiInputBase-root": {
                              color: 'black',
                              width: '100%'
                            },
                            "& .MuiSvgIcon-root":{
                              color: 'black',
                            },
                            "& .MuiFormLabel-root":{
                              color: 'black',
                            },
                            "& .Mui-focused":{
                              color: 'black',
                            },
                            "& .Mui-focused:after":{
                              borderBottom: "1px solid white"
                            }
                           }}
                          placeholder="電話號碼 Contact"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LocalPhoneOutlinedIcon />
                              </InputAdornment>
                            ),
                          }}
                          type="tel"
                          variant="standard"
                          color="warning"
                          focused
                          fullwidth
                          value={visitorPhone}
                          onChange={e => setVisitorPhone(e.target.value.replace(/\D/g, ''))}
                        />
                      </div>
                    </label>
                    <div className="login-bottom">
                      <div className="login-bottom-container">
                        <button type="submit" className="login-bottom-container-submit">繼續 Continue</button>
                      </div>
                    </div>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
};
