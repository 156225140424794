import React, { useState, createRef } from 'react';
import { useParams } from "react-router-dom";
import { ImageBackground , Dimensions, SafeAreaView, ScrollView, StatusBar ,StyleSheet} from "react-native";
import { Paper, stackLayout,List, Grid } from '@mui/material';

import { useScreenshot, createFileName } from "use-react-screenshot";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';



import { Card,CardActionArea,CardContent,CardMedia,Typography } from '@mui/material';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import i18n from '../../i18n';

import axios from "../../axios.js";
import TokenService from '../../TokenService.js';
import LocalStorageTTL from '../../LocalStorageTTL.js';

import { useTranslation } from "react-i18next";
import Logo from '../../logo.png';
import LogoBackground from '../../img/Watermark_Square.png';
import PaymentSuccessImg from '../../img/check-circle.png';

import Header from '../../components/Header.js';

export default function RentalPaymentSuccess() {
  const { t } = useTranslation();
  const isTraditionalChinese = i18n.language == 'zh_hk';
  const [rental, setRental] = useState();
  const { rental_id } = useParams();
  const rental_detail = JSON.parse(LocalStorageTTL.getWithExpiry('rental_detail'));
  localStorage.removeItem('proceed_rentalTable_initialDate');

  console.log(rental_detail);
  //--
  // console.log("getSessionValue");
  // console.log(rental_detail);
  if(rental_detail){
    return(
      <div className="app-wrapper">
        <div className="app-page ">
          <Container>
            <Header />
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <Card
                  style={{
                    width: '100%',
                    borderRadius: '28px',
                    backgroundColor: '#E6EDF3',
                    backgroundImage: `url(${LogoBackground})`,
                    backgroundPosition:'center'
                  }}
                >
                  <CardContent>
                    <div className="app-payment-success-container">
                      <img className="app-payment-success-img" src={PaymentSuccessImg}/>
                      <div className="app-payment-success-text">
                        租用成功！
                      </div>
                      <div className="app-payment-success-text">
                        Rental success!
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        *請螢幕截圖並於使用場地時出示予工作人員
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        *Please screen-capture and give it to our staff
                      </div>
                    </div>
                    <hr/>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        overflow: 'hidden',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 1,
                      }}
                      style={{color:"black",fontWeight: "bold", whiteSpace:'pre-wrap'}}
                      variant="body1"
                    >
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {rental_detail.starting_date.substring(0,10)}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {rental_detail.display_day} / {rental_detail.start_time} - {rental_detail.end_time}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '18px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {/* rental detail */}
                      </div>
                      <hr/>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'left',
                      }}>
                        付款方法 Payment Method - {rental_detail.payment_method}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        編號 No. - {rental_detail.id}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        {(rental_detail.remarks)?("租用說明 Remarks - "+rental_detail.remarks):""}
                      </div>
                      <br/>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: '#4c4c4c',
                        textAlign: 'center',
                      }}>
                        {(rental_detail.payment_remarks)?("場地及器材 Venue & Equipment - "+rental_detail.payment_remarks):""}
                      </div>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}>
                        <img className="app-logo-img" src={rental_detail.image_url}/>
                      </div>
                    </Typography>
                  </CardContent>
                </Card>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
              <Col xs={12} sm={12} md={12} lg={8}>
                <div className="app-page-bottom">
                  <div className="app-bottom">
                    <div className="app-bottom-container">
                      <button type="button" className="app-bottom-container-back" onClick={event =>  window.location.href='/home'}>返回主頁 Back Home</button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={2}>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }else{
    return(<h3></h3>);
  }
};
